<template>
  <div>
    <Nav title="Bạn bè mới" />
    <List url="/rooms/apply/friend">
      <template v-slot="{ item }">
        <UserItem
          :img="item.userimg"
          class="useritem_wrap"
          :text="item.username"
          @click="handleClick(item)"
        />
      </template>
    </List>
  </div>
</template>

<script>
import Nav from "./components/nav.vue";
import List from "@/components/List.vue";
import UserItem from "./components/userItem.vue";
import Dialog from "@/utils/Dialog";
import { $post } from "@/utils/request";
import { Toast } from "vant";
export default {
  components: { Nav, List, UserItem },
  methods: {
    handleClick(item) {
      Dialog({
        content: `Bạn có đồng ý với yêu cầu kết bạn của ${item.username} không?`,
        showCancelButton: true,
      })
        .then(() => {
          this.agree(item.userid);
        })
        .catch(() => {
          // 
        });
    },
    async agree(userid) {
      Toast.loading({ duration: 0 });
      try {
        const {
          data: { ret, msg, data },
        } = await $post("/rooms/add/friend", { userid });
        // Toast.clear();
        Toast(msg);
        if(ret == 1){
          this.toChat(userid,msg)
        }
        // setTimeout(() => {
        //   if (ret == 1) {
        //      this.$router.go(-1);
        //   }
        // }, 1000);
      } catch (error) {
        ;
        Toast.clear();
      }
    },
    async toChat(id,msg) {
      // Toast.loading({duration: 0});
      try {
        const {
          data: { ret, msg, data },
        } = await $post("/rooms/add", { user_ids: id });
        Toast.clear();
        if (ret == 1) {
          this.$router.push(`/ChatRoom?id=${data.id}`);
          return;
        }
        Toast(msg);
      } catch (error) {
        ;
        Toast.clear(); 
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  color: #333;
  font-size: 14px * 2;
  margin-top: 52px * 2;
}
.useritem_wrap {
  margin: 20px 30px 0;
}
</style>