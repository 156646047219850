<template>
  <List v-model="loading" @load="GetListData" :finished="!hasMore" :finished-text="$t('recharge.noDataAvailable')"
    :loading-text="$t('common.loading')" error-text="lỗi mạng vui lòng thử lại sau">
    <div class="item" v-for="(item, index) in !!data ? data : list" :key="item.id || index">
      <slot :item="item"></slot>
    </div>
  </List>
</template>

<script>
import { List, Toast } from 'vant'
import { $post } from '@/utils/request'
export default {
  components: {
    List
  },
  props: {
    data: {
      type: Array
    },
    url: {
      type: String
    },
    defaultParams: {
      type: Object,
      default: () => ({})
    },
    pageSize: {
      type: Number,
      default: 20
    },
    listKey: {
      type: String,
      default: 'lists'
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      hasMore: true,
      page: 0
    }
  },
  methods: {
    async GetListData() {
      if (!this.url) {
        this.hasMore = false
      }
      if (!this.hasMore) return
      this.loading = true
      this.page++
      try {
        const {
          data: { ret, msg, data }
        } = await $post(this.url, this.params)
        this.loading = false
        if (ret == 1) {
          this.list = data[this.listKey]
          this.hasMore = data.hasMorePages
          return
        }
        Toast(msg)
      } catch (error) {
        this.loading = false
        
      }
    }
  },
  computed: {
    params() {
      return {
        ...this.defaultParams,
        pageSize: this.pageSize,
        page: this.page
      }
    }
  }
}
</script>
